export class Industry {
  id: number;
  name: string;
  createdDate: string;
  modifiedDate: string | null;
  category?: IndustryCategory;
}

export class IndustryCategory {
  id: number;
  name: string;
  createdDate: string;
  modifiedDate: string | null;
}
export class Tenant {
  id: number;
  name: string;
  industry: Industry;
  country: string;
  contactUrl: string;
  contactPhone: string;
  contactName: string;
  contactEmail?: string | null;
  createdDate: string;
  modifiedDate?: string | null;
  developer: boolean;
  owner: boolean;
  tenant: boolean;
}

export class TenantDetail {
  warehouse?: string;
  floor?: string;
  module?: string;
  suiteNumber?: string;
  area: string;
  bomaArea?: string;
  moduleEfficiency?: string;
  docksModule?: string;
  tenantName: string;

  owners: string;
  ownersGroup: string;
  ownersContactName: string;
  ownersContactEmail: string;
  ownersContactPhone: string;
}
