<h2
  *ngIf="detailTenantForm.get('tenantName')?.value"
  mat-dialog-title
  class="modal-header"
>
  {{ "detail-tenant.edit" | translate }}:
  {{
    detailTenantForm.get("tenantName")?.value?.tenantName ||
      detailTenantForm.get("tenantName")?.value
  }}
  <button mat-icon-button class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="modal-content">
  <form [formGroup]="detailTenantForm">
    <table class="tenant-table">
      <thead>
        <tr>
          <th
            *ngIf="data?.buildingType == 1001 && data?.buildingType?.id != 3001"
          >
            {{ "detail-tenant.warehouse" | translate }}
          </th>
          <th
            *ngIf="data?.buildingType == 2001 && data?.buildingType?.id != 3001"
          >
            {{ "detail-tenant.floor" | translate }}
          </th>
          <th
            *ngIf="data?.buildingType == 1001 && data?.buildingType?.id != 3001"
          >
            {{ "detail-tenant.module" | translate }}
          </th>
          <th
            *ngIf="data?.buildingType == 2001 && data?.buildingType?.id != 3001"
          >
            {{ "detail-tenant.suite" | translate }}
          </th>
          <th>{{ "detail-tenant.area" | translate }}</th>
          <th
            *ngIf="data?.buildingType == 2001 && data?.buildingType?.id != 3001"
          >
            {{ "detail-tenant.bomaArea" | translate }}
          </th>
          <th
            *ngIf="data?.buildingType == 1001 && data?.buildingType?.id != 3001"
          >
            {{ "detail-tenant.moduleEfficiency" | translate }}
          </th>
          <th
            *ngIf="data?.buildingType == 1001 && data?.buildingType?.id != 3001"
          >
            {{ "detail-tenant.docksModule" | translate }}
          </th>
          <th>{{ "detail-tenant.tenantName" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            *ngIf="
              isIndustry &&
              data?.buildingType == 1001 &&
              data?.buildingType?.id != 3001
            "
          >
            <input
              matInput
              formControlName="warehouse"
              class="table-input form-control"
            />
          </td>
          <td
            *ngIf="
              !isIndustry &&
              data?.buildingType == 2001 &&
              data?.buildingType?.id != 3001
            "
          >
            <input
              matInput
              formControlName="floor"
              class="table-input form-control"
            />
          </td>
          <td
            *ngIf="
              isIndustry &&
              data?.buildingType == 1001 &&
              data?.buildingType?.id != 3001
            "
          >
            <input
              matInput
              formControlName="module"
              class="table-input form-control"
            />
          </td>
          <td
            *ngIf="
              !isIndustry &&
              data?.buildingType == 2001 &&
              data?.buildingType?.id != 3001
            "
          >
            <input
              matInput
              formControlName="suiteNumber"
              class="table-input form-control"
            />
          </td>
          <td>
            <input
              matInput
              formControlName="area"
              class="table-input form-control"
            />
          </td>
          <td
            *ngIf="
              !isIndustry &&
              data?.buildingType == 2001 &&
              data?.buildingType?.id != 3001
            "
          >
            <input
              matInput
              formControlName="bomaArea"
              class="table-input form-control"
            />
          </td>
          <td
            *ngIf="
              isIndustry &&
              data?.buildingType == 1001 &&
              data?.buildingType?.id != 3001
            "
          >
            <input
              matInput
              formControlName="moduleEfficiency"
              class="table-input form-control"
            />
          </td>
          <td
            *ngIf="
              isIndustry &&
              data?.buildingType == 1001 &&
              data?.buildingType?.id != 3001
            "
          >
            <input
              matInput
              formControlName="docksPerModule"
              class="table-input form-control"
            />
          </td>
          <td>
            <div
              style="position: relative; text-align: start; width: 100%;"
              (click)="dropdownClicked = true"
            >
              <input
                type="text"
                formControlName="tenantName"
                (input)="onInputChange($event)"
                (focus)="isDropdownOpen = true"
                placeholder="Type to search..."
                [ngClass]="{ loading: isLoading }"
                style="width: 100%; text-align: left;"
                class="form-control tenant-input"
              />
              <span *ngIf="isLoading" class="loading-icon">🔄</span>
              <ul
                *ngIf="
                  autocompleteResults.length && isDropdownOpen && !isLoading
                "
                class="autocomplete-dropdown"
                style="z-index: 1; list-style: none; padding-left: 0; background: white; border: 1px solid #ccc; position: absolute; width: 100%; max-height: 200px; overflow-y: auto;"
              >
                <li
                  *ngFor="let option of autocompleteResults"
                  (click)="onSelectOption(option)"
                  style="text-align: left; padding: 8px; cursor: pointer;"
                  [ngClass]="{
                    'blue-text': option.tenantName.includes('new tenant')
                  }"
                >
                  {{ option.tenantName }}
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="tenant-table">
      <thead>
        <tr>
          <th>{{ "detail-tenant.owners" | translate }}</th>
          <th>{{ "detail-tenant.ownersGroup" | translate }}</th>
          <th>{{ "detail-tenant.ownersName" | translate }}</th>
          <th>{{ "detail-tenant.ownersEmail" | translate }}</th>
          <th>{{ "detail-tenant.ownersPhone" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input
              matInput
              formControlName="owners"
              class="table-input form-control"
            />
          </td>
          <td>
            <input
              matInput
              formControlName="ownersGroup"
              class="table-input form-control"
            />
          </td>
          <td>
            <input
              matInput
              formControlName="ownersContactName"
              class="table-input form-control"
            />
          </td>
          <td>
            <input
              matInput
              formControlName="ownersContactEmail"
              class="table-input form-control"
            />
          </td>
          <td>
            <input
              matInput
              formControlName="ownersContactPhone"
              class="table-input form-control"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="modal-actions">
  <button mat-button (click)="close()">
    {{ "detail-tenant.close" | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="save()">
    {{ "detail-tenant.save" | translate }}
  </button>
</mat-dialog-actions>
