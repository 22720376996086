<div class="content-detail">
  <div class="header-container">
    <button mat-icon-button class="download-button" (click)="downloadTenants()">
      <mat-icon>cloud_download</mat-icon>
      {{ "detail-tenant.download" | translate }}
    </button>
    <button class="view-vacancy-button" (click)="openVacancy()">
      {{ "detail-tenant.viewVacancy" | translate }}
    </button>
  </div>
  <div class="table-header-banner">
    <div class="header-content">
      <h2 class="table-title">
        {{ "detail-tenant.title" | translate }}
        <a [href]="'property/' + id" target="_blank">{{
          data?.title | langSelector
        }}</a>
      </h2>
      <div class="toggle-container">
        <div class="toggle-switch">
          <span class="toggle-label">{{
            "detail-tenant.summary" | translate
          }}</span>
          <label class="switch">
            <input
              [(ngModel)]="isDetail"
              type="checkbox"
              (ngModelChange)="onDetailToggle($event)"
            />
            <span class="slider"></span>
          </label>
          <span class="toggle-label">{{
            "detail-tenant.detail" | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="table-content">
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th class="wrap-header" *ngIf="data?.buildingType?.id == 3001">
            {{ "detail-tenant.area" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.warehouse" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.module" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.area" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.moduleEfficiency" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.docksModule" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.floor" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.suite" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.privateArea" | translate }}
          </th>
          <th
            class="wrap-header"
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            {{ "detail-tenant.bomaArea" | translate }}
          </th>
          <th class="wrap-header">
            {{ "detail-tenant.tenantName" | translate }}
          </th>
          <th class="wrap-header" *ngIf="data?.buildingType?.id != 3001">
            {{ "detail-tenant.industry" | translate }}
          </th>
          <th class="wrap-header">
            {{ "detail-tenant.contactInformation" | translate }}
          </th>
          <th class="wrap-header">
            {{ "detail-tenant.ownerInformation" | translate }}
          </th>
          <th class="wrap-header">
            {{ "detail-tenant.dateEntered" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tenant of list">
          <td *ngIf="data?.buildingType?.id == 3001">
            <mat-icon (click)="editTenantDetail(tenant, false)">edit</mat-icon>
            {{ tenant?.area | number: "1.2-2" }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            <mat-icon (click)="editTenantDetail(tenant, false)">edit</mat-icon
            >{{ tenant?.warehouse }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            <mat-icon (click)="editTenantDetail(tenant, false)">edit</mat-icon
            >{{ tenant?.floor }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ tenant?.module }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            {{ tenant?.suiteNumber }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ tenant?.area | number: "1.2-2" }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            {{ tenant?.area | number: "1.2-2" }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ tenant?.moduleEfficiency }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 2001 && data?.buildingType?.id != 3001
            "
          >
            {{ tenant?.bomaArea }}
          </td>
          <td
            *ngIf="
              data?.buildingType?.id == 1001 && data?.buildingType?.id != 3001
            "
          >
            {{ tenant?.docksPerModule }}
          </td>
          <td>{{ tenant?.tenantName || translate }}</td>
          <td *ngIf="data?.buildingType?.id != 3001">
            {{ tenant?.marketSegment?.name | langSelector }}
          </td>

          <td>
            <div>
              <span
                *ngIf="
                  !isDetail &&
                  validateContactInformation(tenant) &&
                  !showContactMap.get(tenant.id)
                "
                (click)="toggleDetailsContact(tenant.id)"
                class="view-link"
              >
                {{ "detail-tenant.view" | translate }}
              </span>
            </div>
            <div
              class="information-column"
              *ngIf="
                (isDetail && validateContactInformation(tenant)) ||
                (showContactMap.get(tenant.id) &&
                  validateContactInformation(tenant))
              "
            >
              <span>{{ tenant?.tenantContactName }}</span>
              <span> {{ tenant?.tenantContactPhone }} </span>
              <span>{{ tenant?.tenantContactEmail }}</span>
            </div>
          </td>

          <td>
            <div>
              <span
                *ngIf="
                  !isDetail &&
                  validateOwnersInformation(tenant) &&
                  !showOwnerMap.get(tenant.id)
                "
                (click)="toggleDetailsOwner(tenant.id)"
                class="view-link"
              >
                {{ "detail-tenant.view" | translate }}
              </span>
            </div>
            <div
              class="information-column"
              *ngIf="
                isDetail ||
                !validateOwnersInformation(tenant) ||
                showOwnerMap.get(tenant.id)
              "
            >
              <span>{{ tenant?.owners }}</span>
              <span>{{ tenant?.ownersGroup }}</span>
              <span>{{ tenant?.ownersContactName }}</span>
              <span>{{ tenant?.ownersContactPhone }}</span>
              <span>{{ tenant?.ownersContactEmail }}</span>
            </div>
          </td>
          <td>{{ tenant?.dateEntered | date: "yyyy-MM-dd" }}</td>
        </tr>
      </tbody>
    </table>
    <div class="buttons-container">
      <div class="add-row-container">
        <button
          class="add-row-button"
          (click)="editTenantDetail(null, true)"
          [disabled]="data?.includedInQuarterlyStats"
        >
          {{ "detail-tenant.addNewRow" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
