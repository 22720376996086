import { InjectionToken } from "@angular/core";
import { Routes, ActivatedRouteSnapshot } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import {
  AboutComponent,
  ProductsComponent,
  PrivacyPolicyComponent,
  TermsOfUseComponent,
  BlogRedirectComponent,
  SiilaIndexReportComponent
} from "../shared/components/static-pages";
import { MarketAnalyticsComponent } from "../shared/components/static-pages/market-analytics/market-analytics.component";
import { PageNotFoundComponent } from "../shared/components";
import { AuthGuard } from "./guards";
import { ChangePasswordComponent } from "../shared/components/static-pages/change-password/change-password.component";
import { ProductListComponent } from "../shared/components/static-pages/product-list/product-list.component";
import { GrocsComponent } from "../shared/components/static-pages/grocs/grocs.component";
import { SpotComponent } from "../shared/components/static-pages/spot/spot.component";
import { ProductLayoutComponent } from "../shared/components/static-pages/product-layout/product-layout.component";
import { SolutionLayoutComponent } from "../shared/components/static-pages/solution-layout/solution-layout.component";
import { SolutionComponent } from "../shared/components/static-pages/solution/solution.component";
import { FiiDataProComponent } from "../shared/components/static-pages/fii-data-pro/fii-data-pro.component";
import { GlossaryComponent } from "../shared/components/static-pages/glossary/glossary.component";
import { SiilaAcademyComponent } from "../shared/components/static-pages/siila-academy/siila-academy.component";
import { MetaGuard } from "@ngx-meta/core";
import { DetailNewsComponent } from "../shared/components/detail-news/detail-news.component";
import { HomeNewsComponent } from "../shared/components/home-news/home-news.component";
import { SearchNewsComponent } from "../shared/components/search-news/search-news.component";
import { NewsDetailComponent } from "../modules/news/news-detail.component";
import { PressComponent } from "../shared/components/static-pages/press/press.component";
import { DetailPropertyComponent } from "../shared/components/detail-property/detail-property.component";
import { DetailTransactionsComponent } from "../shared/components/detail-transactions/detail-transactions.component";
import { TenantsPropertyComponent } from "../shared/components/tenants-property/tenants-property.component";
import { StatsDashboardComponent } from "../shared/components/stats-dashboard/stats-dashboard.component";
import { MarketStatsConfigComponent } from "../shared/components/stats-dashboard/market-stats-config.component";
import { MasterTenantsComponent } from "../shared/components/master-tenants/master-tenants.component";
import { TenantsDetailComponent } from "../shared/components/master-tenants/tenants-detail/tenants-detail.component";

export const externalUrlProvider = new InjectionToken(
  "externalUrlRedirectResolver"
);

export const routeProviderValue = function(route: ActivatedRouteSnapshot) {
  const externalUrl = route.paramMap.get("externalUrl");
  window.open(externalUrl, "_blank");
};

const lazyRoutes: Routes = [
  /*  {
    path: "news",
    loadChildren: () =>
      import("../modules/news/news.module").then(m => m.NewsFeatureModule),
    data: { title: "Latest News", description: "Latest Industry News" }
  }, */
  {
    path: "listings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("../modules/listings/listings.module").then(
        m => m.ListingsFeatureModule
      ),
    data: { title: "Listings Search", description: "Listings Search" }
  },
  {
    path: "images",
    loadChildren: () =>
      import("../modules/preview-image/preview-image.module").then(
        m => m.PreviewImageModule
      )
  }
];

const staticRoutes: Routes = [
  {
    path: "news/detail/:id",
    component: NewsDetailComponent,
    data: { title: "Industry News", description: "Industry News" }
  },
  {
    path: "news/detail/:id/lang/:language",
    component: NewsDetailComponent,
    data: { title: "Industry News", description: "Industry News" }
  },
  {
    path: "blog",
    pathMatch: "prefix",
    children: [{ path: "**", component: BlogRedirectComponent }]
  },
  {
    path: "about",
    component: AboutComponent,
    data: { title: "About", description: "SiiLA About" }
  },
  {
    path: "about/lang/:language",
    component: AboutComponent,
    data: { title: "About", description: "SiiLA About" }
  },
  {
    path: "solutions",
    canActivate: [MetaGuard],
    canActivateChild: [MetaGuard],
    component: SolutionLayoutComponent,
    data: { title: "solutions", description: "SiiLS Solutions" },
    children: [
      {
        path: ":id/:name",
        component: SolutionComponent
      },
      {
        path: ":id/:name/lang/:language",
        component: SolutionComponent
      }
    ]
  },
  {
    path: "products",
    canActivate: [MetaGuard],
    canActivateChild: [MetaGuard],
    component: ProductLayoutComponent,
    data: { title: "Products", description: "SiiLA Products" },
    children: [
      {
        path: "",
        component: ProductListComponent
      },
      {
        path: "lang/:language",
        component: ProductListComponent
      },
      {
        path: ":id/:name",
        component: GrocsComponent
      },
      {
        path: ":id/:name/lang/:language",
        component: GrocsComponent
      }
    ]
  },
  {
    path: "marketanalytics",
    component: MarketAnalyticsComponent,
    data: { title: "marketanalytics", description: "SiiLA Analytics" }
  },
  {
    path: "marketanalytics/lang/:language",
    component: MarketAnalyticsComponent,
    data: { title: "marketanalytics", description: "SiiLA Analytics" }
  },
  {
    path: "grocs",
    canActivate: [MetaGuard],
    canActivateChild: [MetaGuard],
    component: GrocsComponent,
    data: { title: "Grocs", description: "SiiLA Grocs" }
  },
  {
    canActivate: [MetaGuard],
    canActivateChild: [MetaGuard],
    path: "grocs/lang/:language",
    component: GrocsComponent,
    data: { title: "Grocs", description: "SiiLA Grocs" }
  },
  {
    path: "fii-data-pro",
    component: FiiDataProComponent,
    data: { title: "Fii", description: "SiiLA Fii-Data-Pro" }
  },
  {
    path: "fii-data-pro/lang/:language",
    component: FiiDataProComponent,
    data: { title: "Fii", description: "SiiLA Fii-Data-Pro" }
  },
  {
    path: "fibra-analytics",
    component: FiiDataProComponent,
    data: { title: "Fii", description: "SiiLA Fii-Data-Pro" }
  },
  {
    path: "fibra-analytics/lang/:language",
    component: FiiDataProComponent,
    data: { title: "Fii", description: "SiiLA Fii-Data-Pro" }
  },
  {
    path: "market-fundamentals",
    component: GlossaryComponent,
    data: { title: "Fii-glossary", description: "SiiLA Fii-Glossary" }
  },
  {
    path: "market-fundamentals/lang/:language",
    component: GlossaryComponent,
    data: { title: "Fii-glossary", description: "SiiLA Fii-Glossary" }
  },
  {
    canActivate: [MetaGuard],
    path: "SPOT",
    component: SpotComponent,
    data: {}
  },
  {
    canActivate: [MetaGuard],
    path: "SPOT/lang/:language",
    component: SpotComponent,
    data: {}
  },
  {
    path: "academy",
    component: SiilaAcademyComponent,
    data: { title: "Academy", description: "SiiLA Academy" }
  },
  {
    path: "academy/lang/:language",
    component: SiilaAcademyComponent,
    data: { title: "Academy", description: "SiiLA Academy" }
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    data: { title: "Change Password", description: "Change Password" }
  },
  {
    path: "change-password/lang/:language",
    component: ChangePasswordComponent,
    data: { title: "Change Password", description: "Change Password" }
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    data: { title: "SiiLA Privacy Policy", description: "SiiLA Privacy Policy" }
  },
  {
    path: "privacy-policy/lang/:language",
    component: PrivacyPolicyComponent,
    data: { title: "SiiLA Privacy Policy", description: "SiiLA Privacy Policy" }
  },
  {
    path: "privacy-policy/:lang",
    component: PrivacyPolicyComponent,
    data: { title: "SiiLA Privacy Policy", description: "SiiLA Privacy Policy" }
  },
  {
    path: "terms-of-use/:lang",
    component: TermsOfUseComponent,
    data: { title: "SiiLA Terms Of Use", description: "SiiLA Terms Of Use" }
  },
  {
    path: "terms-of-use",
    component: TermsOfUseComponent,
    data: { title: "SiiLA Terms Of Use", description: "SiiLA Terms Of Use" }
  },
  {
    path: "terms-of-use/lang/:language",
    component: TermsOfUseComponent,
    data: { title: "SiiLA Terms Of Use", description: "SiiLA Terms Of Use" }
  },
  {
    path: "siila_index",
    component: SiilaIndexReportComponent,
    data: { title: "SiiLA Index Report", description: "SiiLA Index Report" }
  },
  {
    path: "siila_index/lang/:language",
    component: SiilaIndexReportComponent,
    data: { title: "SiiLA Index Report", description: "SiiLA Index Report" }
  },
  {
    path: "index",
    component: SiilaIndexReportComponent,
    data: { title: "SiiLA Index Report", description: "SiiLA Index Report" }
  },
  {
    path: "index/lang/:language",
    component: SiilaIndexReportComponent,
    data: { title: "SiiLA Index Report", description: "SiiLA Index Report" }
  },
  {
    path: "externalRedirect",
    canActivate: [externalUrlProvider],
    component: PageNotFoundComponent
  },
  {
    path: "insights/detail/:id/:title",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "insights/detail/:id/:title/lang/:language",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "news/:title/:id",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "news/:title/:id/lang/:language",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "noticias/:title/:id",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "noticias/:title/:id/lang/:language",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "notícias/:title/:id",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "notícias/:title/:id/lang/:language",
    component: DetailNewsComponent,
    data: { title: "news", description: "SiiLA Detail news" }
  },
  {
    path: "insight-preview/detail/:id/:title",
    component: DetailNewsComponent,
    data: { title: "news", description: "Insight Preview Detail news" }
  },
  {
    path: "insight-preview/detail/:id/:title/lang/:language",
    component: DetailNewsComponent,
    data: { title: "news", description: "Insight Preview Detail news" }
  },
  {
    path: "insights",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "insights/tags/:id/:type/:color",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "insights/lang/:language",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "insights/tags/:id/:type/:color/lang/:language",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "resource",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "resource/:tags/:type/:id/:color",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "resource/lang/:language",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "resource/:tags/:type/:id/:color/lang/:language",
    component: HomeNewsComponent,
    data: { title: "news home", description: "SiiLA home news" }
  },
  {
    path: "search_news",
    component: SearchNewsComponent,
    data: { title: "search news", description: "SiiLA search news" }
  },
  {
    path: "search_news/lang/:language",
    component: SearchNewsComponent,
    data: { title: "search news", description: "SiiLA search news" }
  },
  {
    path: "press",
    component: PressComponent,
    data: { title: "press", description: "SiiLA press" }
  },
  {
    path: "press/lang/:language",
    component: PressComponent,
    data: { title: "press", description: "SiiLA press" }
  },
  {
    path: "press/:keyword",
    component: PressComponent,
    data: { title: "press", description: "SiiLA press" }
  },
  {
    path: "press/:keyword/lang/:language",
    component: PressComponent,
    data: { title: "press", description: "SiiLA press" }
  },
  {
    path: "property/:id",
    component: DetailPropertyComponent,
    data: { title: "detail", description: "SiiLA detail" }
  },
  {
    path: "property/:id/tenants",
    component: TenantsPropertyComponent,
    data: { title: "Tenants stats", description: "Tenants stats" }
  },
  {
    path: "property/:id/tenants/lang/:language",
    component: TenantsPropertyComponent,
    data: { title: "Tenants stats", description: "Tenants stats" }
  },
  {
    path: "tenant-stats/lang/:language",
    component: StatsDashboardComponent,
    data: { title: "Stats Dashboard", description: "Stats Dashboard" }
  },
  {
    path: "tenant-stats",
    component: StatsDashboardComponent,
    data: { title: "Stats Dashboard", description: "Stats Dashboard" }
  },
  {
    path: "tenant-stats/market/:marketId/lang/:language",
    component: StatsDashboardComponent,
    data: { title: "Stats Dashboard", description: "Stats Dashboard" }
  },
  {
    path: "tenant-stats/market/:marketId",
    component: StatsDashboardComponent,
    data: { title: "Stats Dashboard", description: "Stats Dashboard" }
  },
  {
    path: "tenant-stats/market-config/lang/:language",
    component: MarketStatsConfigComponent,
    data: { title: "Market Stats Config", description: "Market Stats Config" }
  },
  {
    path: "tenant-stats/market-config",
    component: MarketStatsConfigComponent,
    data: { title: "Market Stats Config", description: "Market Stats Config" }
  },
  {
    path: "property/:id/lang/:language",
    component: DetailPropertyComponent,
    data: { title: "detail", description: "SiiLA detail" }
  },
  {
    path: "transaction/:id",
    component: DetailTransactionsComponent,
    data: { title: "transaction", description: "SiiLA transaction" }
  },
  {
    path: "transaction/:id/lang/:language",
    component: DetailTransactionsComponent,
    data: { title: "transaction", description: "SiiLA transaction" }
  },
  {
    path: "master-tenants",
    component: MasterTenantsComponent,
    data: { title: "master-tenants", description: "master-tenants" }
  },
  {
    path: "master-tenants/lang/:language",
    component: MasterTenantsComponent,
    data: { title: "master-tenants", description: "master-tenants" }
  },
  {
    path: "property/:id/tenants-detail",
    component: TenantsDetailComponent,
    data: { title: "master-tenants", description: "master-tenants" }
  },
  {
    path: "property/:id/tenants-detail/lang/:language",
    component: TenantsDetailComponent,
    data: { title: "master-tenants", description: "master-tenants" }
  }
];

const notFoundRoutes: Routes = [
  {
    path: "404",
    canActivate: [MetaGuard],
    component: PageNotFoundComponent,
    data: { title: "404", description: "Page not found" }
  },
  { path: "**", pathMatch: "full", component: PageNotFoundComponent }
];

const externalRoutes: Routes = [
  // ... other routes
  { path: "es", redirectTo: "https://siila.com.mx/lang/es", pathMatch: "full" }
];

export const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [MetaGuard],
    canActivateChild: [MetaGuard],
    data: {}
  },
  {
    path: "lang/:language",
    component: HomeComponent,
    canActivate: [MetaGuard],
    canActivateChild: [MetaGuard],
    data: {}
  },
  {
    path: "access/:id",
    component: HomeComponent,
    canActivate: [MetaGuard],
    canActivateChild: [MetaGuard],
    data: {}
  },
  { path: "", redirectTo: "", pathMatch: "full" },
  ...staticRoutes,
  ...lazyRoutes,
  ...notFoundRoutes,
  ...externalRoutes
];

export const RouteProvider: any = {
  provide: externalUrlProvider,
  useValue: routeProviderValue
};
