import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Tenant } from "../models/tenant.model";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class MasterTenantsService {
  constructor(private http: HttpClient) {}

  getMasterTenants(
    page,
    per_page,
    filterName?,
    filterIndustry?,
    filterCountry?,
    filterDate?,
    filterStatus?,
    filterContact?
  ) {
    const filters = {
      page: page,
      perPage: per_page,
      filterName: filterName,
      filterIndustry: filterIndustry,
      filterCountry: filterCountry,
      year: filterDate,
      includedInStats: filterStatus,
      filterContact: filterContact
    };

    return this.http
      .post(`${environment.apiURL}/corporations/tenant/list`, filters)
      .toPromise();
  }

  getIndustries() {
    return this.http
      .get(`${environment.apiURL}/building-rent-rolls/segments`)
      .toPromise();
  }

  updateTenant(tenant: Tenant) {
    return this.http
      .put(`${environment.apiURL}/corporations/tenant/`, tenant)
      .toPromise();
  }

  createTenant(tenant: Tenant) {
    return this.http
      .post(`${environment.apiURL}/corporations/tenant/`, tenant)
      .toPromise();
  }

  getCountries() {
    return this.http
      .get(`${environment.apiURL}/corporations/tenant/country`)
      .toPromise();
  }

  getTenantsDetail(id) {
    return this.http.get(`${environment.apiURL}/buildings/${id}`).toPromise();
  }

  getBuilding(id) {
    return this.http
      .get(`${environment.apiURL}/building-rent-rolls?building=${id}`)
      .pipe(
        map((resp: any) => {
          return resp.map(item => {
            const [year, month, day] = item.dateEntered;
            item.dateEntered = new Date(
              moment.utc({ year, month: month - 1, day }).format("MM/DD/YYYY")
            );
            return item;
          });
        })
      )
      .toPromise();
  }

  updateTenantDetail(buildingId: number, listTenants) {
    return this.http
      .put(
        `${environment.apiURL}/building-rent-rolls?building=${buildingId}`,
        listTenants
      )
      .toPromise();
  }

  downloadTenantDetail(id) {
    let download = {
      buildingIds: [id],
      excelOptions: 2
    };

    return this.http
      .post(
        `${environment.apiURL}/buildingPrints/buildingExcelReport`,
        download
      )
      .toPromise();
  }
}
