import { Component, Inject, OnInit, HostListener } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PropertyService } from "../../../services/property.services";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

@Component({
  selector: "app-edit-detail",
  templateUrl: "./edit-detail.component.html",
  styleUrls: ["./edit-detail.component.scss"]
})
export class EditDetailComponent implements OnInit {
  detailTenantForm: FormGroup;
  errorMessage = false;
  tenants: any;
  floorOrWarehouseControl: string;
  moduleOrSuiteControl: string;
  isIndustry;
  autocompleteResults = [];
  private searchSubject = new Subject<string>();
  isLoading = false;
  isDropdownOpen = false;
  dropdownClicked = false;

  constructor(
    public dialogRef: MatDialogRef<EditDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data;
      buildingType;
      isAdmin;
      includedInQuarterlyStats;
      isCreate;
    },
    private fb: FormBuilder,
    private propertyService: PropertyService
  ) {
    this.isIndustry = this.data?.buildingType === 1001;
    this.initDetailTenantForm();
  }

  ngOnInit(): void {
    if (this.data?.data) {
      this.detailTenantForm.patchValue(this.data?.data);
    }

    this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => {
          this.isLoading = true;
          return this.propertyService.fetchTenantsAutocompleteResults(term);
        })
      )
      .subscribe((results: any[]) => {
        this.autocompleteResults = results;
        this.isLoading = false;
      });
  }

  initDetailTenantForm() {
    this.detailTenantForm = this.fb.group({
      ...(!this.data.isCreate ? { id: new FormControl() } : {}),
      ...(this.isIndustry ? { warehouse: new FormControl() } : {}),
      ...(!this.isIndustry ? { floor: new FormControl() } : {}),
      ...(this.isIndustry ? { module: new FormControl() } : {}),
      ...(!this.isIndustry ? { suiteNumber: new FormControl() } : {}),
      area: new FormControl({
        value: null,
        disabled: this.data.includedInQuarterlyStats
      }),
      ...(!this.isIndustry
        ? {
            bomaArea: new FormControl({
              value: null,
              disabled: this.data.includedInQuarterlyStats
            })
          }
        : {}),
      ...(this.isIndustry ? { moduleEfficiency: new FormControl() } : {}),
      ...(this.isIndustry ? { docksPerModule: new FormControl() } : {}),
      tenantName: new FormControl({
        value: null,
        disabled: this.data.includedInQuarterlyStats
      }),
      owners: new FormControl(),
      ownersGroup: new FormControl(),
      ownersContactName: new FormControl(),
      ownersContactEmail: new FormControl(null, Validators.email),
      ownersContactPhone: new FormControl()
    });
  }

  save() {
    if (this.detailTenantForm.valid) {
      this.dialogRef.close(this.detailTenantForm.value);
    } else {
      this.errorMessage = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  onInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.searchSubject.next(input);
    this.isDropdownOpen = true;
  }

  onSelectOption(option: any): void {
    this.detailTenantForm.get("tenantName")?.setValue(option.tenantName);
    this.autocompleteResults = [];
    this.isDropdownOpen = false;
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    const clickedInside =
      (event.target as HTMLElement).closest(".tenant-input") ||
      (event.target as HTMLElement).closest(".autocomplete-dropdown");
    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }
}
